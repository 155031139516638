<template>
    <div>
        <b-form @submit="onSubmit">
            <b-form-row>
            <b-col cols="12" md="6">
                <label  for="empresa">Nombre Empresa</label>
                    <b-form-input
                        id="empresa"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Nombre Empresa"
                        v-model="form.razon_social"
                    ></b-form-input>
            </b-col>
            
            <b-col cols="12" md="6">
                <label  for="nitEmpresa">Nit Empresa</label>
                    <b-form-input
                        id="nitEmpresa"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Nit Empresa"
                        v-model="form.nit"
                    ></b-form-input>
            </b-col>
           
            <b-col cols="12" md="6">
                <label  for="direccionEmpresa">Dirección Empresa</label>
                    <b-form-input
                        id="direccionEmpresa"
                        class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                        placeholder="Dirección Empresa"
                        v-model="form.direccion"
                    ></b-form-input>
            </b-col>

            <b-col cols="12" md="6"  align-self="end">
                <b-button class="vertical-align" variant="secondary" type="submit" block>
                    <b-icon icon="search"></b-icon>
                    Consultar
                </b-button>
            </b-col>

            </b-form-row>
        </b-form>

        <br /> <br />
        <b-row v-if="empresasServicioBuscar.length>0">
            <b-col>
                <b-table 
                    id="consultaEmpresas" 
                    striped 
                    responsive 
                    small 
                    bordered 
                    :items="empresasServicioBuscar" 
                    :fields="camposTabla" 
                    :per-page="perPage"
                    :current-page="currentPage"
                    :tbody-tr-class="rowClass" 
                    select-mode="single"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected">
                    <template #cell(seleccionar)="{ rowSelected }">
                        <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
            </b-col>
        </b-row>

        <b-row v-if="empresasServicioBuscar.length>0">
            <b-col>
                <b-pagination 
                    v-model="currentPage" 
                    :total-rows="rows" 
                    :per-page="perPage" 
                    aria-controls="consultaLicencias"></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import {encontrarError} from '../../../../contantesErrores'
    export default {
        name: 'FormularioBuscarEmpresas',
        data(){
            return{
                form: {
                    razon_social: null,
                    nit: null,
                    direccion: null,
                },
                camposTabla: [
                    'seleccionar',
                    {
                        key: 'id',
                        label: 'ID Empresa'
                    },
                    {
                        key: 'id_act_economica',
                        label: 'Actividad económica'
                    },
                    {
                        key: 'razon_social',
                        label: 'Razón Social'
                    },
                    {
                        key:'nit',
                        label: 'NIT'
                    },
                    {
                        key:'nombre_representante',
                        label: 'Nombre representante'
                    },
                    {
                        key:'iden_representante',
                        label: 'Identificación representante'
                    },
                ],
                itemSeleccionado: [],
                perPage: 10,
                currentPage: 1,
            }
        },
        computed:{
            ...mapState('consultaEmpresas', ['empresasServicioBuscar']),
            rows() {
                return this.empresasServicioBuscar.length;
            },
        },
        methods:{
            ...mapActions('consultaEmpresas', ['asignarEmpresaSeleccionada', 'MostrarModalBuscar', 'BuscarEmpresas']),
            rowClass(item, type) {
                if (!item || type !== 'row') return
                return 'rowspace'
            },

            onRowSelected(items) {
                this.itemSeleccionado = items
                this.asignarEmpresaSeleccionada(items[0])
                this.MostrarModalBuscar(false)
            },

            async onSubmit(event){
                event.preventDefault();
                let datos = {
                    empresaparams: this.form
                }
                try{
                    await this.BuscarEmpresas(datos)
                    if(this.empresasServicioBuscar.length==0){
                        this.mostrarError()
                    }
                }catch(e){
                    encontrarError(this, e.data)
                }
            },

            mostrarError() {
                this.$bvModal.msgBoxOk('No hay empresas con estos parametros.', {
                    title: 'Error',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>