<template>
    <div>
        <b-form @submit.prevent="enviarForm" validated>
            <b-form-row>

                <b-col cols="12">
                    <label  for="empresa">Empresa</label>
                    <div class="d-flex align-items-center">
                        <b-form-input
                            id="empresa"
                            class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                            placeholder="Empresa"
                            v-model="form.empresa"
                            readonly
                        ></b-form-input>
                        <b-icon icon="search" variant="danger"  @click="activarModalBuscar" class="mb-1"></b-icon>
                    </div>
                </b-col>

                <b-col cols="12" lg="4" align-self="end">
                    <label  for="fechaInicio">Fecha:</label>
                    <b-form-datepicker 
                        id="fecha" 
                        v-model="form.fecha_planeada" 
                        placeholder="Elige una fecha"  
                        locale="es"
                        :min="form.fechaMinima"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        labelNoDateSelected="No hay fecha"
                        labelHelp="Use las flechas del teclado para navegar entre fechas"></b-form-datepicker>
                </b-col>

                <b-col cols="12" lg="4" align-self="end">
                    <label  for="hora">Hora:</label>
                    <b-form-timepicker 
                        required
                        id='hora' v-model="form.hora_planeada" 
                        placeholder="A.M." 
                        labelCloseButton = "Cerrar"
                        labelNoTimeSelected = "No hay hora"
                        locale="es" ></b-form-timepicker>
                </b-col>

                <b-col cols="12" lg="4"  align-self="end">
                    <b-button type="submit" block class="vertical-align mt-2" variant="secondary" :disabled="validarBottonGuardar">
                        <b-icon icon="plus-circle"></b-icon>
                        Agregar Visita
                    </b-button>
                </b-col>
            </b-form-row>
        </b-form>
        <b-modal  
            id="modalBuscar" 
            v-model="mostrarModalBuscarBoolean" 
            hide-footer size="lg" 
            @hide="cerrarModalBuscar">
            <template #modal-title>
                Buscar Empresas
            </template>
            <div class="d-block text-center p-3">
                <FormularioBuscarEmpresas />
            </div>
        </b-modal>
    </div>
</template>

<script>
    import {mapActions, mapState} from 'vuex';
    import moment from 'moment'
    import FormularioBuscarEmpresas from '../consultaEmpresas/buscadorEmpresa/FormularioBuscarEmpresas.vue'

    export default {
        name:'FormularioAgendarVisita',
        components:{
            FormularioBuscarEmpresas
        },
        data(){
            return{
                usuario:{},
                form: {
                    id_empresa: null,
                    empresa: null,
                    fecha_planeada: null,
                    hora_planeada:null,
                    direccion: null,
                    fechaMinima: moment().format('YYYY-MM-DD')
                },
            }
        },
        mounted(){
            if(this.empresaSeleccionada.id){
                this.form.id_empresa = this.empresaSeleccionada.id
                this.form.empresa = this.empresaSeleccionada.razon_social
                this.form.direccion = this.empresaSeleccionada.direccion
            }
            this.$root.$on('bv::modal::hide', () => {
                if(this.empresaSeleccionada.id){
                    this.form.id_empresa = this.empresaSeleccionada.id
                    this.form.empresa = this.empresaSeleccionada.razon_social
                    this.form.direccion = this.empresaSeleccionada.direccion
                }
                this.resetEmpresasBuscar()
            })
            

            if(localStorage.getItem('usuario')){
                try {
                    this.usuario = JSON.parse(localStorage.getItem('usuario'));
                } catch(e) {
                    localStorage.removeItem('usuario');
                }
            }
        },
        computed:{
            ...mapState('consultaEmpresas', ['empresaSeleccionada', 'mostrarModalBuscarBoolean']),
            ...mapState('agendarVisita', ['visitasCargadas']),

            validarBottonGuardar(){
                let validate = false
                if(!this.form.fecha_planeada || !this.form.hora_planeada ||!this.form.id_empresa) {
                    validate = true
                }
                return validate
            },
        },
        methods:{
            ...mapActions('consultaEmpresas', ['MostrarModalBuscar', 'resetEmpresasBuscar']),

            activarModalBuscar(){
                console.log('boton')
                 this.MostrarModalBuscar(true)
            },
            
            cerrarModalBuscar(){
                console.log('boton')
                 this.MostrarModalBuscar(false)
            },

            enviarForm(){
                let datosAGuardar = {
                    visita: { 
                        id_empresa :  this.form.id_empresa,
                        id_usuario :  this.usuario.id_usuario,
                        fecha_registro :  new Date(),
                        fecha_plan :  this.form.fecha_planeada,
                        hora_visita :  this.form.hora_planeada,
                        direccion: this.form.direccion,
                        estado: 0
                    }
                }
                let coinciden = this.validarFechayHora(datosAGuardar.visita)
                if(!coinciden){
                    this.$emit('envioFormulario', datosAGuardar)
                    this.resetForm()
                }else{
                    this.mostrarError()
                }
            },

            resetForm(){
                this.form.fecha_planeada= null
                this.form.hora_planeada= null
            },

            validarFechayHora(datos){
                let coincide;
                for(let i=0; i<this.visitasCargadas.length > 0; i++){
                    let item = this.visitasCargadas[i]
                    if(moment(item.fecha_visita).format('YYYY-MM-DD') == moment(datos.fecha_registro).format('YYYY-MM-DD')){
                        if(item.hora_visita == datos.hora_visita){
                            coincide = true
                            break
                        }else{
                            coincide = false
                        }
                    }else{
                        coincide = false
                    }
                }
                return coincide
            },

            mostrarError() {
                this.$bvModal.msgBoxOk('Ya coincide una visita con esta fecha y hora.', {
                    title: 'Error',
                    size: 'md',
                    buttonSize: 'md',
                    okVariant: 'danger',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                    })
                    .then(() => {
                    })
                    .catch(err => {
                        console.log(err)
                    })
            },
        }
    }
</script>